<template>
  <div class="wrapper">
    <img :src="require(`@/assets/images/${icon}.svg`)" alt="notice icon" />
    <span>{{ messege }}</span>
  </div>
</template>
<script>
export default {
  name: 'notice',
  props: {
    messege: {
      type: String,
      default: 'warning',
      require: true,
    },
    icon: {
      type: String,
      default: 'red_notice',
      require: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  gap: 10px;
  border-radius: 4px;
  align-items: center;
  padding: 8px 16px;
  font-weight: 400;
  color: #ff5252;
  background-color: #ffebee;
  width: fit-content;
}
</style>
